import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {
  FINANCIAL_ASSISTANCE_URL,
  FAQ_URL,
  CONTACT_US_URL,
  GENETIC_TESTING_URL,
} from '../constants';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          pages: {
            home: {
              ctaTitle: 'Welcome to the {{companyName}} Payment Portal',
              ctaContent: 'Pay your bills quickly and securely through our online portal.',
              ctaButton: 'Pay my bill now',
              cards: [
                {
                  title: 'Financial assistance',
                  text: 'Learn more about our programs to help make testing with {{companyName}} more affordable.',
                  actionText: 'View financial assistance ',
                  actionLink: FINANCIAL_ASSISTANCE_URL,
                },
                {
                  title: 'FAQs',
                  text: 'Find answers to common questions about the payment portal and making a payment.',
                  actionText: 'View FAQs',
                  actionLink: FAQ_URL,
                },
                {
                  title: 'Contact us',
                  text: 'If you need to contact us directly, our billing team is available via email, fax, or phone.',
                  actionText: 'View contact us',
                  actionLink: CONTACT_US_URL,
                }
              ],
            },
            fa: {
              title: 'Financial assistance',
              backToFinancialAssistance: 'Back to financial assistance',
              sections: {
                paymentPlans: {
                  title: 'Payment plans',
                  p1: 'If you have any out-of-pocket costs for testing, we offer interest free payment plans for as low as $15 per month, until paid off. ',
                  p2Fragment1: 'Once you get your bill, contact us at ',
                  p2Fragment2: ' or ',
                  p2Fragment3: ' for enrollment.',
                },
                eligibility: {
                  title: 'Eligibility for our financial assistance program',
                  p2: 'To find out if you qualify, view our financial eligibility calculator.',
                  buttonText: 'Financial eligibility calculator'
                },
                application: {
                  title: 'Financial assistance application',
                },
                promise: {
                  title: 'The {{promise}} Promise',
                }
              },
              ecModal: { // eligibility calculator
                title: 'Financial eligibility calculator*',
                p1: 'Some restrictions apply; patients with government-funded programs, such as Medicaid, Medicare, Medicare-Advantage and Tricare may not be eligible; call for details.',
                p2Note: '*Note:',
                p2: 'The Financial Criteria above are based upon the United States Department of Health & Human Services (HHS) Poverty Guidelines, which are subject to change. Myriad reserves the right to terminate or modify its Financial Assistance Program at any time.'
              },
              careModal: {
                trigger: 'View {{programName}} Patient Certification',
                title: '{{programName}} Patient Certification',
                agreeToTerms: 'By submitting your 1040 tax form and email address for the {{fullProgramName}} Application, you agree to the following:',
              }
            },
            faq: {
              title: 'FAQs',
              eobModal: {
                title: 'Example of an EOB and a bill',
                eobTrigger: 'View an example of an EOB and a bill',
                accountNumberTrigger: 'View an example of an account number',
                this: 'This',
                is: 'is',
                isNot: 'is not',
                aBill: 'a bill',
              },
              accountNumberModal: {
                title: 'Example of an invoice with an account number',
              },
              noSurprisesActLink: 'Learn more about billing',
            },
            contactUs: {
              title: 'Contact us',
              contactChannelsText: 'You can contact a billing representative through any of the channels listed on this page.',
              accountNumber: 'account number',
              shownOnInvoice: 'shown on your invoice',
              viewLinks: 'Visit these links for information on the following:',
              supportLinks: [
                {
                  title: 'How do I pay my bill? ',
                  link: FAQ_URL,
                },
                {
                  title: 'Can I get help with the cost of my test? ',
                  link: FINANCIAL_ASSISTANCE_URL,
                },
                {
                  title: 'General questions about genetic testing',
                  link: GENETIC_TESTING_URL,
                },
              ],
              phone: {
                title: 'Phone',
                p1: 'Live reps are available Monday - Friday 8am - 8pm Eastern Time',
                p2: 'After-hour payments are accepted 24hrs a day through our automated system',
                p3Fragment1: 'Please have your',
                p3Fragment2: 'ready when you call',
              },
              fax: {
                title: 'Fax',
              },
              email: {
                title: 'Email',
              },
              billingAddress: {
                title: 'Billing address'
              }
            }
          },
          general: {
            or: ' or ',
          },
          faCalc: {
            stateLabel: 'Select your state',
            householdSizeLabel: 'Household Size',
            incomeLevel: 'Income level',
            defaultCalcText: 'Please select a location and your household size to view information about your eligibility for financial assistance.',
            tierTexts: [
              // eslint-disable-next-line
              'If medical eligibility criteria met and household income less than above, you may qualify for testing at ${{tierPrice}}',
              // eslint-disable-next-line
              'If medical eligibility criteria met and household income less than above, you may qualify for testing at ${{tierPrice}}',
              // eslint-disable-next-line
              'If medical eligibility criteria met and household income less than above, you may qualify for testing at ${{tierPrice}}',
            ]
          }
        },
      },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
