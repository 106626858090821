import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Navbar from'./Navbar';
import RedirectExternal from './RedirectExternal';
import Home from '../pages/Home';
import FinancialAssistance from '../pages/FinancialAssistance';
import FAQs from '../pages/FAQs';
import ContactUs from '../pages/ContactUs';

import { useIsTheme } from '../utils/hooks';

import {
  HOME_URL,
  FINANCIAL_ASSISTANCE_URL,
  FAQ_URL,
  CONTACT_US_URL,
  MYRIAD_BILLING_URL
} from '../constants';

const RouterView = ({ themeDetails }) => {
  const isGenesight = useIsTheme('neuro');

  return (
    <BrowserRouter>
      <Navbar
        class="navbar"
        themeDetails={themeDetails} />
        <Switch>
          <Route path={FINANCIAL_ASSISTANCE_URL}>
            <FinancialAssistance />
          </Route>
          {/* Redirect everything but Genesight theme and financial assistance page to Myriad billing site, order does matter for these routes */}
          {!isGenesight && (
            <Route>
              <RedirectExternal to={MYRIAD_BILLING_URL} />
            </Route>
          )}
          <Route exact path={HOME_URL}>
            <Home />
          </Route>
          <Route path={FAQ_URL}>
            <FAQs />
          </Route>
          <Route path={CONTACT_US_URL}>
            <ContactUs />
          </Route>
          <Route>
            This is a 404
          </Route>
        </Switch>
    </BrowserRouter>
  );
}

export default RouterView;
