import { useContext } from "react";
import BuContext from "../components/BuContext";

/**
 * Determines whether context's theme matches particular theme
 * @param { 'myriad' | 'neuro' | 'autoImmune' } theme 
 * @returns { Boolean } isTheme
 */
export const useIsTheme = (theme) => {
  const { theme: contextTheme } = useContext(BuContext);

  return contextTheme === theme;
};

export default {
  useIsTheme,
};
