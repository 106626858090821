import React, { useState } from 'react';
import { ThemeProvider } from 'primase';
import RouterView from './components/RouterView';
import Footer from './components/Footer';
import { BuProvider } from './components/BuContext';
import { getTheme } from './utils/theme';
import { getXifinPortalUrl } from './utils/xifin';
import { initializeGa } from './components/Analytics';
import themes from './themes';
import myriadLogo from './img/myriad.svg';
import genesightLogo from './img/genesight.svg';
import vectraLogo from './img/vectra.svg';
import './lang/i18n';
import 'primase/build/main.css';
import './css/App.css';

const logos = {
  myriad: myriadLogo,
  neuro: genesightLogo,
  autoImmune: vectraLogo,
};

const theme = getTheme();
const xifinPortalUrl = getXifinPortalUrl(theme);
const logo = logos[theme];

// GA Setup
initializeGa(theme);


// create the theme context
const themeSettings = {
  theme,
  xifinPortalUrl,
  logo,
};


const App = () => {
  const initialAppState = {
    theme,
    xifinPortalUrl,
    logo,
  };

  useState(initialAppState);

  return (
    <div id='application-wrapper' className={`${theme}-theme`}>
      <ThemeProvider theme={themes[theme]}>
        <BuProvider value={themeSettings}>
          <RouterView
            themeDetails={{
              xifinPortalUrl,
              logo,
            }} />
          <Footer />
        </BuProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
