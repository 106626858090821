import React from "react";
import {
  Grid,
} from 'primase';

const AccountNumberModal = ({ accountNumberImage }) => {
  return (
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <img style={{ width: '100%' }} alt="EOB Example" src={accountNumberImage} />
      </Grid>
    </Grid>
  );
};

export default AccountNumberModal;
