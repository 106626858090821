import { Styles } from 'primase';
export default {
  typography: {
    variants: {
      h2: {
        color: Styles.color.neutral.white
      },
    },
  },
  button: {
    primary: {
      fontWeight: 'bold',
    }
  }
};
