import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ReactGA from 'react-ga';

const linkStyles = {
  textDecoration: 'none',
};

export const Link = ({ children, styles = {}, ...rest }) => (
  <RouterLink {...rest} style={Object.assign({}, linkStyles, styles)}>
    { children }
  </RouterLink>
);

export const ExternalLink = ({ children, styles = {}, ...rest }) => (
  <ReactGA.OutboundLink
    style={Object.assign({}, linkStyles, styles)}
    eventLabel={`external-link`}
    {...rest}
  >
    { children }
  </ReactGA.OutboundLink>
);

export const A = ({ children, styles = {}, ...rest }) => (
  <a {...rest} style={Object.assign({}, linkStyles, styles)}>
    { children }
  </a>
);


export default Link;
