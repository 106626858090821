import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { getBuLangText } from '../utils/buContext';
import BuContext from '../components/BuContext';
import {
  View,
} from 'primase';

const styles = {
  ul: {
    margin: 0,
    paddingLeft: 0,
  },
  li: {
    fontSize: '14px',
    paddingBottom: '8px',
  },
};

const CarePatientCertificationModal = () => {
  const { t } = useTranslation();
  const buContext = useContext(BuContext);
  const buContent = getBuLangText()[buContext.theme];
  const { terms }  = buContent.mFap;

  return(
    <View classes={styles.container} className="care-patient-certification">
      <p>{t('pages.fa.careModal.agreeToTerms', { fullProgramName: buContent.mFap.fullProgramName})}</p>
      <ul style={styles.ul}>
        {terms.map((term, index) => (
          <li style={styles.li} key={`term-${index}`}>
            {term}
          </li>
        ))}
      </ul>
    </View>
  );
};

export default CarePatientCertificationModal;
