const colors = {
  primary: '#007DB3',
  secondary: '#7AC142',
  primaryFaded: '#007DB390',
};

export default {
  button: {
    primary: {
      backgroundColor: colors.primary,
      fontWeight: 'bold',
      '&:hover': {
        color: colors.primary,
        borderColor: colors.primaryFaded,
      },
    },
    secondary: {
      color: colors.primary,
      borderColor: '#66CBF2',
      '&:hover': {
        color: colors.primary,
        borderColor: colors.primaryFaded,
      },
    },
  },
  navigation: {
    tab: {
      active: {
        '&:after': {
          borderTop: `solid 4px ${colors.secondary}`,
        },
      }
    },
  },
  card: {
    header: {
      root: {
        fontFamily: "'Raleway', sans-serif",
      }
    }
  },
  modal: {
    title: {
      fontFamily: "'Raleway', sans-serif",
      backgroundColor: colors.primary,
    },
  },
}