import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactGA from 'react-ga';
import { getBuLangText } from '../utils/buContext';
import BuContext from '../components/BuContext';
import Title from '../components/Title';
import Banner from '../components/Banner';
import FinancialAssistanceCalculator from '../components/FinancialAssistanceCalculator';
import CarePatientCertificationModal from '../components/CarePatientCertificationModal';
import { useTracking } from '../components/Analytics';
import { A } from '../components/Link';
import {
  Button,
  Grid,
  Modal,
  View,
} from 'primase';

const styles = {
  modal: {
    container: {
      default: {
        maxWidth: '768px',
        left: 0,
        right: 0,
        margin: '0 auto',
      },
    },
  },
  button: {
    secondary: {
      maxWidth: '270px'
    },
  },
  modalTrigger: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};

const FinancialAssistance = () => {
  const { t } = useTranslation();
  const buContext = useContext(BuContext);
  const buContent = getBuLangText()[buContext.theme];
  const [faCalcModalDisplay, setFaCalcModalDisplay] = useState({ open: false});
  const [careModalDisplay, setCareModalDisplay] = useState({ open: false});
  useTracking();
  return (
    <React.Fragment>
      <Title title="Financial assistance" />
      <Banner />
      <div className="page-container">
        <h1>
          {t('pages.fa.title')}
        </h1>
        <Grid container direction={"column"} spacing={24}>
          <Grid item sm={12} md={8}>
            <section>
              <h2>{t('pages.fa.sections.paymentPlans.title')}</h2>
              <p>{t('pages.fa.sections.paymentPlans.p1')}</p>
              <p>
                {t('pages.fa.sections.paymentPlans.p2Fragment1')}
                <strong>{buContent.supportInfo.phone}</strong>
                {t('pages.fa.sections.paymentPlans.p2Fragment2')}
                <a href={`mailto:${buContent.supportInfo.email}`}>{buContent.supportInfo.email}</a>
                {t('pages.fa.sections.paymentPlans.p2Fragment3')}
              </p>
            </section>
            <section>
              <h2>{t('pages.fa.sections.eligibility.title')}</h2>
              <p>{buContent.eligibilityText}</p>
              <p>
                <strong>{t('pages.fa.sections.eligibility.p2')}</strong>
              </p>
              <View>
                <Button
                  onClick={() => {
                    ReactGA.modalview('/financial-assistance-calculator');
                    setFaCalcModalDisplay({ open: true })
                  }}
                  secondary
                  inline
                  classes={styles.button.secondary}
                >
                    {t('pages.fa.sections.eligibility.buttonText')}*
                </Button>
              </View>
            </section>
            <section>
              <h2>{t('pages.fa.sections.application.title')}</h2>
              <p>{buContent.applicationText}</p>
              <iframe
                id="financial-assistance-iframe"
                style={styles.iframe}
                src={buContent.forms.financialAssistanceFormUrl}
                title="Financial Assistance Application Form">
              </iframe>
              <p>
                <A
                  styles={styles.modalTrigger}
                  onClick={() => {
                    ReactGA.modalview('/care-modal');
                    setCareModalDisplay({ open: true });
                  }}
                >
                  {t('pages.fa.careModal.trigger', { programName: buContent.mFap.programName})}
                </A>
              </p>
            </section>
            <section>
              <h2>{t('pages.fa.sections.promise.title', {promise: buContent.promise})}</h2>
              <p>
                {buContent.promiseText1}
                {!buContent.promiseText2 &&
                  <span>
                    <strong>{buContent.supportInfo.phone}</strong>
                    {t('general.or')}
                    <a href={`mailto:${buContent.supportInfo.email}`}>{buContent.supportInfo.email}</a>.
                  </span>
                }
              </p>
              {/* because Genesight is special */}
              {buContent.promiseText2 &&
                <p>
                  {/* TODO: make component */}
                  {buContent.promiseText2}
                  <span>
                    <strong>{buContent.supportInfo.phone}</strong>
                    {t('general.or')}
                    <a href={`mailto:${buContent.supportInfo.email}`}>{buContent.supportInfo.email}</a>.
                  </span>
                </p>
              }
            </section>
          </Grid>
        </Grid>
      </div>
      <Modal
        open={faCalcModalDisplay.open}
        onClose={() => setFaCalcModalDisplay({ open: false })}
        title={t('pages.fa.ecModal.title')}
        classes={styles.modal}
      >
        <FinancialAssistanceCalculator />
      </Modal>
      <Modal
        open={careModalDisplay.open}
        onClose={() => setCareModalDisplay({ open: false })}
        title={t('pages.fa.careModal.title', {programName: buContent.mFap.programName})}
        classes={styles.modal}
      >
        <CarePatientCertificationModal />
      </Modal>
    </React.Fragment>
  );
}

export default FinancialAssistance;