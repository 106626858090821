import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable';
import 'url-search-params-polyfill';
import {
  ALLOWED_THEMES,
  THEME_MAP,
  XIFIN_HOST,
  XIFIN_REFERRAL_PARTIALS_THEME_MAP,
} from '../constants';

const getQueryParamValue = (url, queryParam) => {
  /*eslint-disable-next-line no-useless-escape */
  const name = queryParam.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(url);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}
export const getTheme = () => {
  // setup some constants
  const themeKey = 'theme';
  const legacyThemeKey = 'template';
  const defaultTheme = 'myriad';
  const genesightHostPartial = 'genesight';

  // get url params
  const urlParams = new URLSearchParams(window.location.search);
  // get referrer
  const referUrl = document.referrer;
  // first precedent is based on referer
  if (referUrl && referUrl !== '' && referUrl.includes(XIFIN_HOST)) {
    const referralTheme = getQueryParamValue(referUrl, 'tn');
    if (referralTheme && XIFIN_REFERRAL_PARTIALS_THEME_MAP[referralTheme]) {
      return XIFIN_REFERRAL_PARTIALS_THEME_MAP[referralTheme]
    }
  }

  // for backwards compatibility with old webpay that uses template query param
  const isRequestingNewTheme = (
    urlParams.has(themeKey) && ALLOWED_THEMES.includes(urlParams.get(themeKey))
  ) || (
    urlParams.has(legacyThemeKey) && ALLOWED_THEMES.includes(urlParams.get(legacyThemeKey))
  );

  // short circuit if it's genesight host
  if (window.location.hostname.includes(genesightHostPartial)) {
    return 'neuro';
  }

  if (isRequestingNewTheme) {
    // set the new theme if setting
    const newThemeKey = urlParams.get(themeKey) || urlParams.get(legacyThemeKey);
    const newTheme = THEME_MAP[newThemeKey];
    window.localStorage.setItem(themeKey, newTheme);
    return newTheme;
  }
  
  if (window.localStorage.getItem(themeKey)) {
    return window.localStorage.getItem(themeKey);
  }

  // safely return default
  return defaultTheme;
}

export default {
  getTheme,
};
