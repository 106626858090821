import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './App';
import serviceWorker from './serviceWorker';

import { getTheme } from './utils/theme';

import { MYRIAD_BILLING_URL } from './constants';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

const isGenesight = getTheme() === 'neuro';
const isFinancialAssistance = window.location.pathname?.includes('/financial-assistance');

if (isFinancialAssistance || isGenesight) {
  ReactDOM.render(
    <React.Fragment>
      <App />
    </React.Fragment>,
    document.getElementById('root')
  );
} else {
  window.location.replace(MYRIAD_BILLING_URL);
}
