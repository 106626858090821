import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import BuContext from '../components/BuContext';
import {
  Grid,
  Styles,
} from 'primase';

const isBillColorMap = {
  myriad: Styles.color.messages.error,
  neuro: Styles.color.messages.error,
  autoImmune: '#E08433', 
}

const isNotBillColorMap = {
  myriad: Styles.color.primary.primary5,
  neuro: '#7AC142',
  autoImmune: '#6C2888', 
}

const EobInvoiceModal = ({ eobImage, invoiceSampleImage }) => {
  const { t } = useTranslation();
  const buContext = useContext(BuContext);

  return (
    <Grid container spacing={24}>
      {eobImage &&
        <Grid item xs={12} sm={12} md={invoiceSampleImage ? 6 : 12}>
          <h3>
            {t('pages.faq.eobModal.this')}
            &nbsp;
            <span className="is-not-bill" style={{color: isNotBillColorMap[buContext.theme]}}>
              {t('pages.faq.eobModal.isNot')}
            </span>
            &nbsp;
            {t('pages.faq.eobModal.aBill')}
          </h3>
          <img alt="EOB Example" src={eobImage} />
        </Grid>
      }
      {invoiceSampleImage &&
        <Grid item xs={12} sm={12} md={eobImage ? 6 : 12}>
          <h3>
            {t('pages.faq.eobModal.this')}
            &nbsp;
            <span className="is-bill" style={{color: isBillColorMap[buContext.theme]}}>
              {t('pages.faq.eobModal.is')}
            </span>
            &nbsp;
            {t('pages.faq.eobModal.aBill')}
          </h3>
          <img alt="Statement Example" src={invoiceSampleImage} />
        </Grid>
      }
    </Grid>
  );
}

export default EobInvoiceModal;
