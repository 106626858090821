import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactGA from 'react-ga';
import { getBuLangText } from '../utils/buContext';
import BuContext from '../components/BuContext';
import Title from '../components/Title';
import Banner from '../components/Banner';
import AccountNumberModal from '../components/AccountNumberModal';
import { A } from '../components/Link';
import { useTracking } from '../components/Analytics';
import getAndPreloadImage from '../utils/getAndPreloadImage';
import {
  Card,
  CardContent,
  Grid,
  Modal,
  Styles,
} from 'primase';

const styles = {
  modalTrigger: {
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  card: {
    marginTop: Styles.spacing.sp2,
  },
  modal: {
    container: {
      default: {
        maxWidth: '1032px',
        left: 0,
        right: 0,
        margin: '0 auto',
      },
    },
  },
  question: {
    list: {
      paddingLeft: 0,
    },
    listItems: {
      listStyle: 'none',
      paddingTop: Styles.spacing.sp2,
      paddingBottom: Styles.spacing.sp2,
    },
  },
};

const ContactUs = () => {
  const { t } = useTranslation();
  const buContext = useContext(BuContext);
  const buContent = getBuLangText()[buContext.theme]
  const [accountNumberModalDisplay, setAccountNumberModalDisplay] = useState({ open: false});
  const contactUsQuestions = t('pages.contactUs.supportLinks', { returnObjects: true });
  useTracking();

  return (
    <React.Fragment>
      <Title title="Contact us" />
      <Banner />
      <div className="page-container">
        <h1>
          {t('pages.contactUs.title')}
        </h1>
        <Grid container spacing={24} direction={"column"}>
          <Grid item sm={12} md={8}>
            <p className="big-text">{t('pages.contactUs.contactChannelsText')}</p>
          </Grid>
        </Grid>
        
        <Grid container direction={"row"} spacing={0}>
          <Grid item xs={12} md={6} lg={6}>
            <Grid container direction="column">
              <Grid item>
                <section>
                  <h3>{t('pages.contactUs.phone.title')}</h3>
                  <p><strong>{buContent.supportInfo.phone}</strong></p>
                  <p>{t('pages.contactUs.phone.p1')}</p>
                  <p>{t('pages.contactUs.phone.p2')}</p>
                  <p>
                    {t('pages.contactUs.phone.p3Fragment1')}
                    &nbsp;
                    <A
                      styles={styles.modalTrigger}
                      onClick={() => {
                        ReactGA.modalview('/eob-modal');
                        setAccountNumberModalDisplay({ open: true });
                      }}
                    >
                      {t('pages.contactUs.accountNumber')}
                    </A>
                    &nbsp;
                    {t('pages.contactUs.phone.p3Fragment2')}
                  </p>
                </section>
                <section>
                  <h3>{t('pages.contactUs.fax.title')}</h3>
                  <p><strong>{buContent.supportInfo.fax}</strong></p>
                </section>
                <section>
                  <h3>{t('pages.contactUs.email.title')}</h3>
                  <p><a href={`mailto:${buContent.supportInfo.email}`}>{buContent.supportInfo.email}</a></p>
                </section>
                <section>
                  <h3>{t('pages.contactUs.billingAddress.title')}</h3>
                  <p>
                    {buContent.supportInfo.address1}
                    <br />
                    {buContent.supportInfo.cityState}
                    <br />
                    {buContent.supportInfo.postalCode}
                  </p>
                </section>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={8} md={5} lg={5}>
            <iframe
              id="contact-us-iframe"
              style={styles.iframe}
              src={buContent.forms.contactFormUrl}
              title="Contact Form">
            </iframe>
            <Card classes={styles.card}>
              <CardContent>
                <h4>{t('pages.contactUs.viewLinks')}</h4>
                <ul style={styles.question.list}>
                  {contactUsQuestions.map((question, index) => (
                    <li key={`contact-question-${index}`} style={styles.question.listItems}>
                      <A href={question.link}>
                        {question.title}
                      </A>
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        
        <Modal
            open={accountNumberModalDisplay.open}
            onClose={() => setAccountNumberModalDisplay({ open: false })}
            title={t('pages.faq.accountNumberModal.title')}
            classes={styles.modal}
          >
            <AccountNumberModal
              accountNumberImage={getAndPreloadImage('account', buContext.theme)}
            />
          </Modal>
      </div>
    </React.Fragment>
  );
};

export default ContactUs;