import React from 'react';
import { NavLink } from "react-router-dom";
import { MenuItem, Styles } from 'primase';

const styles = {
  menuItem: {
    height: 'auto',
    padding: 0,
  },
  navLink: {
    display: 'block',
    padding: Styles.spacing.sp2,
    width: '100%',
  },
}

const MobileLink = ({link, active}) => {
  return (
    <MenuItem style={styles.menuItem} className={active ? 'active' : 'inactive'}>
      <NavLink
        style={styles.navLink}
        key={link.href}
        to={link.href}>
        {link.title}
      </NavLink>
    </MenuItem>
  );
};

MobileLink.defaultProps = {
  link: {
    href: '',
    title: '',
  },
  active: false,
};

export default MobileLink;
