import React, { useState } from 'react';
import bowser from 'bowser';
import PropTypes from 'prop-types';
import {
  Icon,
  Menu,
  Styles,
  View,
} from 'primase';
import MediaQuery from 'react-responsive';

const styles = {
  menuIcon: {
    paddingTop: Styles.spacing.sp2,
    paddingBottom: Styles.spacing.sp2,
    paddingLeft: Styles.spacing.sp4,
    paddingRight: Styles.spacing.sp4,
    cursor: 'pointer',
    fontSize: '1.75em',
    color: Styles.color.neutral.neutral6
  },
  linkContainer: {
    paddingBottom: Styles.spacing.sp0,
  },
};

const isIE = bowser.msie;

const MobileMenuIcon = ({ onClick }) => (
  <MediaQuery maxWidth={Styles.layout.breakpoints.md}>
    <Icon classes={styles.menuIcon} onClick={onClick}>
      menu
    </Icon>
  </MediaQuery>
);

MobileMenuIcon.propTypes = {
  onClick: PropTypes.func,
};

const MobileMenu = ({ children, anchorEl }) => {

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = (event) => {
    setMenuOpen(!menuOpen);

  }

  const closeMenu = () => {
    setMenuOpen(false);
  }

  return (
    <View>
      <View data-testid="mobile-menu-button" onClick={toggleMenu}>
        <MobileMenuIcon onClick={toggleMenu} />
      </View>
      <Menu
        data-testid="mobile-menu"
        anchor={anchorEl}
        open={menuOpen}
        onClick={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={isIE ? { style: { width: 300 } } : {}}
      >
        {children}
      </Menu>
    </View>
  );
};

MobileMenu.defaultProps = {
  children: [],
  anchorEl: null,
};

export default MobileMenu;
