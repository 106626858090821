// pages/Home.js
import React, { useContext } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from 'primase';
import { useTranslation } from "react-i18next";
import BuContext from '../components/BuContext';
import { getBuLangText } from '../utils/buContext';
import Banner from '../components/Banner';
import Title from '../components/Title';
import { A, ExternalLink } from '../components/Link';
import { useTracking } from '../components/Analytics';

const styles = {
  h1: {
    color: '#36393B',
  },
  cardContent: {
    padding: '60px 72px 0px 72px',
  },
  cta: {
    cardContent: {
      padding: '0px 72px 60px 72px',
      alignItems: 'baseline',
      justifyContent: 'space-between'
    },
    a: {
      alignSelf: 'flex-end',
    },
  },
  cardSmall: {
    padding: '8px',
    cardTitle: {
      fontWeight: 'bold',
      fontSize: '24px',
      '&:after': {
        borderBottom: 'none',
      },
    },
    cardContent: {
      paddingTop: '0px',
      paddingBottom: '8px',
    },
  },
  p: {
    minHeight: '72px',
  },
};

const Home = () => {
  const { t } = useTranslation();
  const buContext = useContext(BuContext);
  const buContent = getBuLangText()[buContext.theme];
  const cards = t('pages.home.cards',
    {
      testingType: buContent.testingType,
      companyName: buContent.simpleCompanyName,
      returnObjects: true 
    }
  );
  useTracking();

  return (
    <React.Fragment>
      <div className={'full-body-bg'}></div>
      <Title title="Online Payment System" />
      {buContext.theme !== 'neuro' &&
        <Banner id={'home-page-banner'} />
      }
      <div className={'page-container page-container--home'}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Card className={'home-card'}>
              <CardContent classes={styles.cardContent}>
                <h1 style={styles.h1} dangerouslySetInnerHTML={
                  {__html: t('pages.home.ctaTitle', 
                      { 
                        companyName: buContent.companyName,
                        interpolation: {
                          escapeValue: false
                        }
                      }
                    )
                  }}
                ></h1>
                <p>{t('pages.home.ctaContent')}</p>
              </CardContent>
              <CardContent classes={styles.cta.cardContent}>
                <ExternalLink
                  data-testid={'xifin-portal-button'}
                  styles={styles.cta.a}
                  to={buContext.xifinPortalUrl}
                >
                  <Button
                    large
                  >
                    {t('pages.home.ctaButton')}
                  </Button>
                </ExternalLink>
              </CardContent>
            </Card>
          </Grid>
          {cards && Array.isArray(cards) && cards.map((card, index) =>
              <Grid item xs={12} sm={12} md={4} key={`home-page-card--${index}`}>
                <Card classes={styles.cardSmall} className={'home-page-card--small'}>
                  <CardHeader classes={styles.cardSmall.cardTitle}>
                    {card.title}
                  </CardHeader>
                  <CardContent css={styles.cardSmall.cardContent}>
                    <p style={styles.p}>
                      {card.text}
                    </p>
                    <p>
                      <A href={card.actionLink}>{card.actionText}</A>
                    </p>
                  </CardContent>
                </Card>
              </Grid>
            )
          }
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default Home;