import myriadEobExample from '../img/eob-bill.svg';
import autoImmuneEobExample from '../img/autoImmune-eob-bill.svg';
import neuroEobExample from '../img/neuro-eob-example.png';

import neuroInvoiceExample from '../img/neuro-invoice-sample.jpg';
import autoImmuneInvoiceExample from '../img/autoImmune-invoice-sample.svg';
import myriadInvoiceExample from '../img/myriad-invoice-sample.svg';

import myriadAccountNumberExample from '../img/myriad-account-number.png';
import neuroAccountNumberExample from '../img/neuro-account-number.png';
import autoImmuneAccountNumberExample from '../img/autoImmune-account-number.png';

const imageThemeMaps = {
  eob: {
    myriad: myriadEobExample,
    neuro: neuroEobExample,
    autoImmune: autoImmuneEobExample,
  },
  invoice: {
    myriad: myriadInvoiceExample,
    neuro: neuroInvoiceExample,
    autoImmune: autoImmuneInvoiceExample,
  },
  account: {
    myriad: myriadAccountNumberExample,
    neuro: neuroAccountNumberExample,
    autoImmune: autoImmuneAccountNumberExample,
  },
};

// preload this image and then return
const getAndPreloadImage = (image, theme) => {
  const img = imageThemeMaps[image][theme];
  if (!img) {
    return false;
  }

  new Image().src = img;
  return img;
}

export default getAndPreloadImage;
