import React, { useContext } from 'react';
import NumberFormat from 'react-number-format';
import { useTranslation } from "react-i18next";
import { getBuLangText } from '../utils/buContext';
import BuContext from '../components/BuContext';
import {
  Grid,
  Notification,
  View,
} from 'primase';

const getIncomeLevel = (usState = '', householdSize = 3, level) => {
  
  const financialCriteria = window.webpayConfig.financialCriteria;
  if (!financialCriteria) {
    console.error('missing financial criteria configuration');
    return false;
  } 

  // don't flip to negative
  const householdMultiplier = (householdSize - 1) > 0
    ? (householdSize - 1)
    : 0;

  const basePrice = financialCriteria.basePrices[usState]
      || financialCriteria.basePrices.default;
    
  const increment = financialCriteria.increments[usState]
    || financialCriteria.increments.default;
  
  return (basePrice + (increment * householdMultiplier)) * level;
}

// for our 3 tiers that pricing is based upon
const tiers = [
  { tier: 1, povertyLevel: 2 },
  { tier: 2, povertyLevel: 3 },
  { tier: 3, povertyLevel: 4 },
];

const FinancialAssistanceCalculatorResults = (props) => {
  const { t } = useTranslation();
  const buContext = useContext(BuContext);
  const buContent = getBuLangText()[buContext.theme];
  return (
    <View>
      {props.usState !== 'none' && props.householdSize &&
        <Grid container spacing={24}>
          <Grid item xs={12}>
              <h4>{t('faCalc.incomeLevel')}</h4>
          </Grid>
          {tiers.map(({ tier, povertyLevel }, index) => {
            const results = getIncomeLevel(props.usState, props.householdSize, povertyLevel)
              || 'Unable to process financial criteria';
            
            return (
              <Grid item xs={12} sm={8} md={4} key={`tier-pricing-${index}`}>
                <View data-testid={`tier-pricing-${tier}`}>
                  {typeof results === 'string' &&
                    <Notification type="error">{results}</Notification>
                  }
                  {typeof results === 'number' && 
                    <NumberFormat
                      value={results}
                      displayType="text"
                      thousandSeparator={true}
                      prefix="$"
                      suffix=".00"
                      isNumericString={true}
                    />
                  }
                </View>
                <p>
                  {t(`faCalc.tierTexts.${index}`, {tierPrice: buContent.faTier[index]})}
                </p>
              </Grid>
            );
          })}
        </Grid>
      }
      {(props.usState === 'none' || !props.householdSize) &&
        <p data-testid="default-calc-text">{t('faCalc.defaultCalcText')}</p>
      }
    </View>
  )
}

export default FinancialAssistanceCalculatorResults;
