import React from 'react';

const Banner = (props) => {
  return (
      <div
        id={props.id}
        className="banner"
      />
  );
}

export default Banner;
