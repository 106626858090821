import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactGA from 'react-ga';
import { getBuLangText } from '../utils/buContext';
import BuContext from '../components/BuContext';
import Title from '../components/Title';
import Banner from '../components/Banner';
import EobInvoiceModal from '../components/EobInvoiceModal';
import AccountNumberModal from '../components/AccountNumberModal';
import { A, ExternalLink } from '../components/Link';
import { useTracking } from '../components/Analytics';
import getAndPreloadImage from '../utils/getAndPreloadImage';

import {
  Button,
  Grid,
  Modal,
  View,
} from 'primase';

const styles = {
  payMyBillButton: {
    a: {
      alignSelf: 'flex-end',
      margin: '24px 0',
    },
  },
  modalTrigger: {
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  modal: {
    container: {
      default: {
        maxWidth: '1032px',
        left: 0,
        right: 0,
        margin: '0 auto',
      },
    },
  },
};
/* TODO: break up into 3 components...page, section, modal */
const FAQs = () => {
  const { t } = useTranslation();
  const buContext = useContext(BuContext);
  const buContent = getBuLangText()[buContext.theme];
  const [eobModalDisplay, setEobModalDisplay] = useState({ open: false});
  const [accountNumberModalDisplay, setAccountNumberModalDisplay] = useState({ open: false});
  useTracking();

  return (
    <React.Fragment>
      <Title title="Frequently asked questions" />
      <Banner />
        <div className="page-container">
          <h1>
            {t('pages.faq.title')}
          </h1>
          <Grid container direction={"column"} spacing={24}>
            <Grid item sm={12} md={8}>
              <div className="faqs-sections">
                {/* loop through the faqs per bu */}
                {buContent.faqs && Array.isArray(buContent.faqs) && buContent.faqs.map((faq, index) => (
                  <section key={`faq-section-${index}`}>
                    <h3>{faq.question}</h3>
                    {/* the inner html can only come from the buContent...NOT user input */}
                    {faq.answers && Array.isArray(faq.answers) && faq.answers.map((answer, index) => (
                      <p key={`faq-answer-${index}`} dangerouslySetInnerHTML={{__html: answer}}></p>
                    ))}
                    {/* series of options that will print various items (button or modal trigger) */}
                    {faq.includeAccountNumberTrigger &&
                      <p>
                        <A
                          styles={styles.modalTrigger}
                          onClick={() => {
                            ReactGA.modalview('/account-number-modal');
                            setAccountNumberModalDisplay({ open: true });
                          }}
                        >
                          {t('pages.faq.eobModal.accountNumberTrigger')}
                        </A>
                      </p>
                    }
                    {faq.includeEobTrigger &&
                      <p>
                        <A
                          styles={styles.modalTrigger}
                          onClick={() => {
                            ReactGA.modalview('/eob-modal');
                            setEobModalDisplay({ open: true });
                          }
                        }
                        >
                          {t('pages.faq.eobModal.eobTrigger')}
                        </A>
                      </p>
                    }
                    {faq.includeNoSurprisesActLink &&
                      <p>
                        <ExternalLink
                          to={buContent.noSurprisesActUrl}
                          target="_blank"
                          styles={{ textDecoration: 'underline' }}
                        >
                          {t('pages.faq.noSurprisesActLink')}
                        </ExternalLink>
                      </p>
                    }
                    {faq.includePayBillButton &&
                      <View>
                        <ExternalLink
                          data-testid="xifin-portal-button-faq"
                          styles={styles.payMyBillButton.a}
                          to={buContext.xifinPortalUrl}
                        >
                          <Button
                          >
                            {t('pages.home.ctaButton')}
                          </Button>
                        </ExternalLink>
                      </View>
                    }
                  </section>
                ))}
              </div>
            </Grid>
          </Grid>
          <Modal
            open={eobModalDisplay.open}
            onClose={() => setEobModalDisplay({ open: false })}
            title={t('pages.faq.eobModal.title')}
            classes={styles.modal}
          >
            <EobInvoiceModal
              eobImage={getAndPreloadImage('eob', buContext.theme)}
              invoiceSampleImage={getAndPreloadImage('invoice', buContext.theme)}
            />
          </Modal>
          <Modal
            open={accountNumberModalDisplay.open}
            onClose={() => setAccountNumberModalDisplay({ open: false })}
            title={t('pages.faq.accountNumberModal.title')}
            classes={styles.modal}
          >
            <AccountNumberModal
              accountNumberImage={getAndPreloadImage('account', buContext.theme)}
            />
          </Modal>
        </div>
    </React.Fragment>
  );
};

export default FAQs;
