import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useMediaQuery } from 'react-responsive'
import FinancialAssistanceCalculatorResults from '../components/FinancialAssistanceCalculatorResults';
import usStates from '../constants/usStates';
import {
  MenuItem,
  Select,
  Styles,
  Text,
  TextField,
  View,
} from 'primase';

const styles = {
  container: {
    width: 'calc(100% + 24px)',
    margin: '-12px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  twoCol: {
    flexDirection: 'row',
    width: '49%',
  },
  oneCol: {
    width: '100%',
  },
  selectView: {
    borderRadius: '4px',
    border: `1px solid ${Styles.color.neutral.neutral3}`,
    padding: `10px ${Styles.spacing.sp2}`,
    width: '100%'
  },
  select: {
    '&:before': {
        borderBottom: 'none',
      },
  },
  flexRow: {
    flexDirection: 'row',
  },
  textFieldView: {
    display: 'inline-flex',
    padding: 0,
    alignItems: 'flex-start',
  },
  textField: {
    root: {
      margin: 0,
      border: `1px solid ${Styles.color.neutral.neutral3}`,
      maxWidth: '64px',
      borderRadius: '4px',
    },
  },
  label: {
    display: 'inline-flex',
    maxWidth: '50%',
    padding: Styles.spacing.sp2,
  },
  mobileViewContainer: {
    flexDirection: 'row',
    marginTop: Styles.spacing.sp3,
  },
};

const FinancialAssistanceCalculator = () => {
  const { t } = useTranslation();
  const [usState, setUsState] = useState('none');
  const [householdSize, setHouseholdSize] = useState(3);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  const changeHouseHoldSize = event => {
    event.preventDefault();
    setHouseholdSize(event.target.value);
  };

  const changeUsState = event => {
    setUsState(event.target.value);
  };

  return(
    <View classes={styles.container} className="fa-calc-results">
      <View classes={isMobile ? styles.oneCol : styles.twoCol}>
        <View classes={styles.selectView}>
          <Select
            className="fa-select"
            value={usState}
            onChange={(event) => changeUsState(event)}
            classes={styles.select}
          >
            <MenuItem value='none'>{t('faCalc.stateLabel')}</MenuItem>
            {usStates.map((usState, index) => (
              <MenuItem key={`us-state-${index}`} value={usState.Code}>
                {usState.State}
              </MenuItem>
            ))}
          </Select>
        </View>
      </View>
      <View classes={isMobile ? {...styles.oneCol, ...styles.mobileViewContainer } : styles.twoCol}>
        <Text classes={styles.label}>{t('faCalc.householdSizeLabel')}:</Text>
        <View
          className="household-size-number"
          classes={styles.textFieldView}
        >
          <TextField
            classes={styles.textField}
            onChange={(event) => changeHouseHoldSize(event)}
            min="1"
            max="8"
            step="1"
            name="household-size"
            type="number"
            value={householdSize}
          />
        </View>
      </View>
      <View classes={styles.oneCol}>
        <br />
        <FinancialAssistanceCalculatorResults
          usState={usState}
          householdSize={householdSize}
        />
        <hr />
        <p className="tiny-text">{t('pages.fa.ecModal.p1')}</p>
        <p className="tiny-text">
          <strong>{t('pages.fa.ecModal.p2Note')}</strong>
          {t('pages.fa.ecModal.p2')}
        </p>
      </View>
    </View>
  );
};

export default FinancialAssistanceCalculator;
