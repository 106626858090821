import React, { useContext } from 'react';
import {
  Grid,
  Styles,
} from 'primase';
import { ExternalLink } from './Link';
import BuContext from '../components/BuContext';
import { getBuLangText } from '../utils/buContext';

const styles = {
  footer: {
    bottom: 0,
    color: Styles.color.neutral.neutral5,
    fontSize: '14px',
    marginTop: Styles.spacing.sp5,
    padding: Styles.spacing.sp4,
  },
  lastCol: {
    textAlign: 'right',
  },
  footerLinksListItem: {
    display: 'inline-block',
    listStyle: 'none',
    paddingRight: Styles.spacing.sp1,
    paddingLeft: Styles.spacing.sp1,
    fontWeight: 'bold',
  },
  item: {
    width: '100%',
    textAlign: 'center'
  }
};

const Footer = () => {
  const buContext = useContext(BuContext);
  const buContent = getBuLangText()[buContext.theme];

  return (
    <div style={styles.footer}>
      <Grid container direction="column" justify="center" spacing={24}>
        <Grid item justify="center" sm={12} md={buContent.footer.desktopGrid} style={styles.item}>
          <p className='footer-section'>
            <strong>
              &copy; {new Date().getFullYear()}
              &nbsp;
              {buContent.footer.copyrightText}
            </strong>
          </p>
          {buContent.footer.address && 
            <p className='footer-section'>
              {buContent.footer.address}
            </p>
          }
          {/* if we have trademark text put it in the middle */ }
          {
            buContent.footer.trademarkText &&
              <p className='footer-section'>
                {buContent.footer.trademarkText}
              </p>
          }
          <ul className='footer-section'>
            {buContent.footer.links.map((link, index) => (
              <li style={styles.footerLinksListItem} key={`footer-link-${index}`}>
                <ExternalLink
                  eventLabel={`footer-link-${link.text}`}
                  to={link.href}
                  target={link.target}
                >
                  {link.text}
                </ExternalLink>
              </li>
            ))}
          </ul>
          {buContent.footer.secureMessage && 
            <p className='footer-section'>
              {buContent.footer.secureMessage}
            </p>
          }
        </Grid>
      </Grid>
    </div>
  );
}

export default Footer;