const colors = {
  primary: '#6C2888',
  secondary: '#E08433',
  primaryFaded: '#6C288890',
  primaryBg: '#3F0440',
};

export default {
  button: {
    primary: {
      backgroundColor: colors.primary,
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: colors.primaryFaded,
      },
      border: 'none',
    },
    secondary: {
      color: colors.primary,
      border: `1px solid ${colors.primaryFaded}`,
      '&:hover': {
        color: colors.primary,
        borderColor: colors.primaryFaded,
      },
    },
  },
  navigation: {
    tab: {
      active: {
        '&:after': {
          borderTop: `solid 4px ${colors.secondary}`,
        },
      }
    },
  },
  card: {
    header: {
      root: {
        fontFamily: "'Montserrat', sans-serif",
      },
    },
  },
  modal: {
    title: {
      fontFamily: "'Raleway', sans-serif",
      backgroundColor: colors.primaryBg,
    },
  },
};
