export const HOME_URL = '/';
export const FINANCIAL_ASSISTANCE_URL = '/financial-assistance';
export const FAQ_URL = '/faq';
export const CONTACT_US_URL = '/contact-us'
export const GENETIC_TESTING_URL = 'https://myriad.com/healthcare-professionals/about-genetic-testing/overview/';
export const ALLOWED_THEMES = [
  'neuro',
  'autoImmune',
  'myriad',
  'crescendo',
  'assurex',
];

export const XIFIN_PORTAL_URLS = {
  myriad: 'https://www.dxlink.com/patientportal/signin.html?cn=mgl&tn=myriad&login=statement',
  neuro: 'https://www.dxlink.com/patientportal/signin.html?cn=mgl&tn=genesight&login=statement',
  autoImmune: 'https://www.dxlink.com/patientportal/signin.html?cn=mgl&tn=crescendo&login=statement'
};

// the following possible query param values map to these themes
export const THEME_MAP = {
  myriad: 'myriad',
  ai: 'autoImmune',
  neuro: 'neuro',
  autoImmune: 'autoImmune',
  crescendo: 'autoImmune',
  assurex: 'neuro',
};

export const XIFIN_REFERRAL_PARTIALS_THEME_MAP = {
  myriad: 'myriad',
  genesight: 'neuro',
  crescendo: 'autoImmune',
  Genesight: 'neuro',
  Crescendo: 'autoImmune',
};

export const XIFIN_HOST = 'https://www.dxlink.com';

export const BALANCE_BILLING_URL = 'https://www.myriad.com/your-rights-and-protections-against-surprise-medical-bills/';

export const MYRIAD_FINANCIAL_ASSISTANCE_URL = 'https://myriad.com/affordability/financial-assistance';
export const MYRIAD_BILLING_URL = 'https://myriad.com/billing';
