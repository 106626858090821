import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ReactGA from 'react-ga';

const defaultTrackingId = 'UA-93767733-3';

export const initializeGa = (theme) => {
  ReactGA.initialize(window.webpayConfig.gaTrackingId || defaultTrackingId);
  ReactGA.set({ dimension1: theme });
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const useTracking = () => {
  
  const { listen } = useHistory();
  const trackingId = window.webpayConfig.gaTrackingId || defaultTrackingId;

  useEffect(() => {
    const unlisten = listen(() => {
      if (!trackingId) {
        console.log(
          'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.'
        )
        return;
      }
      ReactGA.pageview(window.location.pathname + window.location.search);
    });

    return unlisten
  }, [trackingId, listen]);
}