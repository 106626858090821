import {
  FAQ_URL,
  CONTACT_US_URL,
  BALANCE_BILLING_URL,
} from '../constants';

export const getBuLangText = () => ({
  myriad: {
    companyName: 'Myriad',
    simpleCompanyName: 'Myriad',
    productName: 'Myriad',
    testingType: 'genetic',
    footer: {
      desktopGrid: 6,
      copyrightText: 'Myriad Genetics Inc. All rights reserved.',
      links: [
        {
          text: 'Terms of service',
          href: 'https://myriad.com/terms-of-use/',
          target: '_blank',
        },{
          text: 'Privacy policy',
          href: 'https://myriad.com/privacy-policy/',
          target: '_blank',
        },{
          text: 'Help',
          href: FAQ_URL,
          target: '_self',
        },
        {
          text: 'Contact',
          href: CONTACT_US_URL,
          target: '_self',
        }
      ],
    },
    supportInfo: {
      phone: '844.697.4239',
      email: 'billinghelp@myriad.com',
      address1: '320 Wakara Way',
      cityState: 'Salt Lake City, UT',
      postalCode: '84108',
      fax: '801-883-3374'
    },
    eligibilityText: 'We will work with your health insurance company to obtain coverage for the test ordered by your provider. If you cannot afford the out-of-pocket costs of your test, you may be eligible for Myriad’s Financial Assistance Program.',
    applicationText: 'If you would like to enroll in Myriad\'s Financial Assistance program, please complete your application below. In order to verify your eligibility, please enter your email address and upload your most recent 1040 tax form. Our billing department will contact you if they have any questions.',
    promise: 'Myriad',
    promiseText1: 'The Myriad Promise is our commitment to provide patients with accurate and affordable genetic results. If you have any questions regarding the cost of testing, please contact Myriad at ',
    faTier: [ // these tiers represent base costs for a given test/income level
      0,
      100,
      295
    ],
    faqs: [
      {
        question: 'How do I pay my bill using Myriad’s payment portal?',
        answers: [
          'The Myriad payment portal allows you to view your statements and pay your bill immediately using a credit card, debit or e-check.',
        ],
        includePayBillButton: true,
        includeAccountNumberTrigger: false,
        includeEobTrigger: false,
      },
      {
        question: 'What’s an account number?',
        answers: [
          'Your bill (or statement) will have a unique number that helps our billing representatives identify you with your test. This unique number is one of the ways that we verify your identity to protect your PHI (Personal Health Information).',
        ],
        includePayBillButton: false,
        includeAccountNumberTrigger: true,
        includeEobTrigger: false,
      },
      {
        question: 'What’s the difference between an Explanation of Benefits (EOB) and a bill?',
        answers: [
          'An Explanation of Benefits (EOB) is a notification sent by your insurance company that describes how they covered the cost of services you received from a healthcare provider, like Myriad. The EOB shows the amount paid by your insurance company and the amount that you may owe. An EOB is not a bill and doesn’t typically require you to take any action.',
          'A bill (or statement) is a request for payment from a healthcare provider and shows the amount of money you owe for a service you received.'
        ],
        includePayBillButton: false,
        includeAccountNumberTrigger: false,
        includeEobTrigger: true,
      },
      {
        question: 'How do I get my test results?',
        answers: [
          'The results of your test will be sent to your healthcare provider within about two to three weeks. He or she will meet with you to explain what the test found. Your results may be more than simply "positive" or "negative." Visit <a href="https://mysupport360.com/hereditary-cancer/post-test-support/">MySupport360</a> for more information about our testing proccess.',
        ],
        includePayBillButton: false,
        includeAccountNumberTrigger: false,
        includeEobTrigger: false
      },
      {
        question: 'Can I speak to someone at Myriad about my results?',
        answers: [
          'After receiving the test results from your healthcare provider, if you have additional questions about your result, you can contact <a href="mailto:helpmed@myriad.com">helpmed@myriad.com</a>.',
        ],
        includePayBillButton: false,
        includeAccountNumberTrigger: false,
        includeEobTrigger: false,
      },
      {
        question: 'Is my genetic information protected by Myriad?',
        answers: [
          'At Myriad, we understand the importance of your privacy and respect every patient\'s right to protected health information.',
          'Visit <a href="https://myriad.com/patients-families/the-myriad-difference/your-privacy">https://myriad.com/patients-families/the-myriad-difference/your-privacy</a> for more information about our privacy policy.'
        ],
        includePayBillButton: false,
        includeAccountNumberTrigger: false,
        includeEobTrigger: false,
      },
      {
        question: 'What are my rights and protections against surprise medical bills?',
        answers: [
          'Myriad is committed to accurate billing and does not intend to balance bill patients. The No Surprises Act protects you from balance billing for out-of-network services in emergency services and certain services at an in-network hospital or ambulatory surgical center.'
        ],
        includePayBillButton: false,
        includeAccountNumberTrigger: false,
        includeEobTrigger: false,
        includeNoSurprisesActLink: true,
      },
    ],
    forms: {
      contactFormUrl: 'https://myriad.tfaforms.net/3',
      financialAssistanceFormUrl: 'https://myriad.tfaforms.net/4',
    },
    mFap: {
      programName: '',
      fullProgramName: 'Myriad\'s Financial Assistance Program',
      shortProgramName: 'Myriad\'s Financial Assistance',
      terms: [
        'If I do not have insurance, I certify I am not eligible for Medicare, Medicaid, or any other state or government health insurance and will not seek reimbursement from any insurance carrier or government agency for fees waived.',
        'If I have insurance, I certify that I will not seek reimbursement from any insurance carrier or government agency for fees that are my financial responsibility. ',
        'I certify that the information contained in this application is correct to the best of my knowledge. I understand this information will not be used for any other purpose unless I give written consent, or to the extent necessary to document my eligibility under Myriad\'s Financial Assistance Program.',
        'I certify I will notify Myriad\'s Financial Assistance Program within 30 days if there is any change in my eligibility status with regard to income and health care coverage. I will provide documentation, including but not limited to personal financial records, which are necessary to verify the information contained in this application.',
        'By agreeing to testing, I authorize Billing to obtain a consumer credit report on me from a consumer reporting agency selected by Billing. I understand and agree that Billing may use my consumer credit report to confirm whether my income qualifies me for financial assistance. I further understand that this will be a soft credit inquiry and will not impact my credit in any way.',
        'If I qualify for financial assistance, I agree to provide Billing with any additional information or documentation that may be needed to confirm my qualification for the financial assistance program.',
      ],
    },
    noSurprisesActUrl: BALANCE_BILLING_URL,
  },
  neuro: {
    companyName: 'GeneSight<sup>&reg;</sup>',
    simpleCompanyName: 'GeneSight',
    productName: 'GeneSight',
    testingType: 'genetic',
    footer: {
      desktopGrid: 4,
      copyrightText: 'Assurex Health, Inc. doing business as Myriad Neuroscience.',
      address: '6960 Cintas Blvd Mason, OH 45040 United States',
      trademarkText: 'GeneSight and associated logos are registered trademarks of Assurex Health, Inc. All Rights Reserved.',
      links: [
        {
          text: 'Terms of service',
          href: 'https://genesight.com/terms-of-use/',
          target: '_blank',
        },{
          text: 'Privacy policy',
          href: 'https://genesight.com/privacy-policy/',
          target: '_blank',
        },{
          text: 'Help',
          href: FAQ_URL,
          target: '_self',
        },
        {
          text: 'Contact',
          href: CONTACT_US_URL,
          target: '_self',
        }
      ],
      secureMessage: 'Your data is secure and encrypted'
    },
    supportInfo: {
      phone: '888.496.2391',
      email: 'billing@assurexhealth.com',
      address1: 'PO Box 645682',
      cityState: 'Cincinnati, OH',
      postalCode: '45264-5682',
      fax: '888.894.4344'
    },
    eligibilityText: 'After your test is processed, we’ll submit your claim to your insurance company. If you are commercially insured, you may be eligible for the GeneSight Financial Assistance Program.',
    applicationText: 'If you would like to enroll in the GeneSight Financial Assistance program, please complete your application below. In order to verify your eligibility, please enter your email address and upload your most recent 1040 tax form. Our billing department will contact you if they have any questions.',
    promise: 'GeneSight',
    promiseText1: 'The GeneSight Promise is our commitment to provide patients with accurate and affordable genetic results. If you have any questions regarding the cost of testing, please contact Genesight at ',
    faTier: [ // these tiers represent base costs for a given test/income level
      0,
      100,
      200
    ],
    faqs: [
      {
        question: 'How do I pay my bill using the GeneSight’s payment portal? ',
        answers: [
          'The GeneSight payment portal allows you to view your statements and pay your bill immediately using a credit card, debit or e-check.',
        ],
        includePayBillButton: true,
        includeAccountNumberTrigger: false,
        includeEobTrigger: false,
      },
      {
        question: 'What’s an account number?',
        answers: [
          'Your bill (or statement) will have a unique number that helps our billing representatives identify you with your test. This unique number is one of the ways that we verify your identity to protect your PHI (Personal Health Information).',
        ],
        includePayBillButton: false,
        includeAccountNumberTrigger: true,
        includeEobTrigger: false,
      },
      {
        question: 'What’s the difference between an Explanation of Benefits (EOB) and a bill?',
        answers: [
          'An Explanation of Benefits (EOB) is a notification sent by your insurance company that describes how they covered the cost of services you received from a healthcare provider, like GeneSight. The EOB shows the amount paid by your insurance company and the amount that you may owe. An EOB is not a bill and doesn’t typically require you to take any action.',
          'A bill (or statement) is a request for payment from a healthcare provider and shows the amount of money you owe for a service you received.',
        ],
        includePayBillButton: false,
        includeAccountNumberTrigger: false,
        includeEobTrigger: true,
      },
      {
        question: 'How do I enroll in a payment plan?',
        answers: [
          'If your cost is $100 or more, we offer an interest-free payment plan that allows you to spread out your cost over multiple months. Contact our billing team at <strong>888.496.2391</strong> to enroll.'
        ],
        includePayBillButton: false,
        includeAccountNumberTrigger: false,
        includeEobTrigger: false,
      },
      {
        question: 'What are my rights and protections against surprise medical bills?',
        answers: [
          'Myriad is committed to accurate billing and does not intend to balance bill patients. The No Surprises Act protects you from balance billing for out-of-network services in emergency services and certain services at an in-network hospital or ambulatory surgical center.'
        ],
        includePayBillButton: false,
        includeAccountNumberTrigger: false,
        includeEobTrigger: false,
        includeNoSurprisesActLink: true,
      },
    ],
    forms: {
      contactFormUrl: 'https://myriad.tfaforms.net/6',
      financialAssistanceFormUrl: 'https://myriad.tfaforms.net/5',
    },
    mFap: {
      programName: '',
      fullProgramName: 'Assurex Health\'s Financial Assistance Program',
      shortProgramName: 'Assurex Health\'s Financial Assistance Financial Assistance',
      terms: [
        'If I do not have insurance, I certify I am not eligible for Medicare, Medicaid, or any other state or government health insurance and will not seek reimbursement from any insurance carrier or government agency for fees waived.',
        'If I have insurance, I certify that I will not seek reimbursement from any insurance carrier or government agency for fees that are my financial responsibility. ',
        ' I certify that the information contained in this application is correct to the best of my knowledge. I understand this information will not be used for any other purpose unless I give written consent, or to the extent necessary to document my eligibility under Assurex Health\'s Financial Assistance Program.',
        'I certify I will notify Assurex Health\'s Financial Assistance Program within 30 days if there is any change in my eligibility status with regard to income and health care coverage. I will provide documentation, including but not limited to personal financial records, which are necessary to verify the information contained in this application.',
        'By agreeing to testing, I authorize Billing to obtain a consumer credit report on me from a consumer reporting agency selected by Billing. I understand and agree that Billing may use my consumer credit report to confirm whether my income qualifies me for financial assistance. I further understand that this will be a soft credit inquiry and will not impact my credit in any way.',
        'If I qualify for financial assistance, I agree to provide Billing with any additional information or documentation that may be needed to confirm my qualification for the financial assistance program.',
      ],
    },
    noSurprisesActUrl: BALANCE_BILLING_URL,
  },
  autoImmune: {
    companyName: 'Crescendo',
    simpleCompanyName: 'Crescendo',
    productName: 'Vectra',
    testingType: '',
    footer: {
      desktopGrid: 12,
      copyrightText: 'Crescendo Bioscience, Inc.',
      address: '341 Oyster Point Blvd, South San Francisco, CA 94080, United States',
      links: [
        {
          text: 'Terms of service',
          href: 'https://myriad.com/terms-of-use/',
          target: '_blank',
        },{
          text: 'Privacy policy',
          href: 'https://myriad.com/privacy-policy/',
          target: '_blank',
        },{
          text: 'Help',
          href: FAQ_URL,
          target: '_self',
        },
        {
          text: 'Contact',
          href: CONTACT_US_URL,
          target: '_self',
        }
      ],
    },
    supportInfo: {
      phone: '855-407-4744',
      email: 'vectrabilling@myriad.com',
      address1: 'PO Box 645682',
      cityState: 'Cincinnati, OH',
      postalCode: '45264-5682',
      fax: '801-883-8965',
    },
    eligibilityText: 'We will work with your health insurance company to obtain coverage for the test ordered by your provider. If you cannot afford your out-of-pocket costs related to the test, you may be eligible for assistance through Vectra CARE Financial Assistance if your household income meets guidelines based on the Federal Poverty Rates.',
    applicationText: 'In order to verify your eligibility for the Vectra Financial Assistance CARE program, please enter your email address and upload your most recent 1040 tax form. Our billing department will contact you if they have any questions.',
    promise: 'Myriad',
    promiseText1: 'At Crescendo, a Myriad Genetics, Inc. company, the Myriad Promise is our commitment to provide patients with accurate and affordable genetic results. If you have any questions regarding the cost of testing, please contact Myriad at ',
    faTier: [ // these tiers represent base costs for a given test/income level
      0,
      45,
      90
    ],
    faqs: [
      {
        question: 'How do I pay my bill using Crescendo\'s payment portal?',
        answers: [
          'The Crescendo payment portal allows you to view your statements and pay your bill immediately using a credit card, debit or e-check.'
        ],
        includePayBillButton: true,
        includeAccountNumberTrigger: false,
        includeEobTrigger: false,
      },
      {
        question: 'What’s an account number?',
        answers: [
          'Your bill (or statement) will have a unique number that helps our billing representatives identify you with your test. This unique number is one of the ways that we verify your identity to protect your PHI (Personal Health Information).'
        ],
        includePayBillButton: false,
        includeAccountNumberTrigger: true,
        includeEobTrigger: false,
      },
      {
        question: 'What’s the difference between an Explanation of Benefits (EOB) and a bill?',
        answers: [
          'An Explanation of Benefits (EOB) is a notification sent by your insurance company that describes how they covered the cost of services you received from a healthcare provider, like Crescendo. The EOB shows the amount paid by your insurance company and the amount that you may owe. An EOB is not a bill and doesn’t typically require you to take any action.',
          'A bill (or statement) is a request for payment from a healthcare provider and shows the amount of money you owe for a service you received.',
        ],
        includePayBillButton: false,
        includeAccountNumberTrigger: false,
        includeEobTrigger: true,
      },
      {
        question: 'What are my rights and protections against surprise medical bills?',
        answers: [
          'Myriad is committed to accurate billing and does not intend to balance bill patients. The No Surprises Act protects you from balance billing for out-of-network services in emergency services and certain services at an in-network hospital or ambulatory surgical center.'
        ],
        includePayBillButton: false,
        includeAccountNumberTrigger: false,
        includeEobTrigger: false,
        includeNoSurprisesActLink: true,
      },
    ],
    forms: {
      contactFormUrl: 'https://myriad.tfaforms.net/8',
      financialAssistanceFormUrl: 'https://myriad.tfaforms.net/7',
    },
    mFap: {
      programName: 'CARE',
      fullProgramName: 'Vectra Financial Assistance CARE',
      shortProgramName: 'CARE program',
      terms: [
        'If I do not have insurance, I certify I am not eligible for Medicare, Medicaid, or any other state or government health insurance and will not seek reimbursement from any insurance carrier or government agency for Vectra fees waived. ',
        'If I have insurance, I certify that I will not seek reimbursement from any insurance carrier or government agency for Vectra fees that are my financial responsibility. ',
        'I certify that the information contained in this application is correct to the best of my knowledge. I understand this information will not be used for any other purpose unless I give written consent, or to the extent necessary to document my eligibility under the CARE program.',
        'I certify I will notify the Vectra Financial Assistance CARE Program within 30 days if there is any change in my eligibility status with regard to income and health care coverage. I will provide documentation, including but not limited to personal financial records, which are necessary to verify the information contained in this application. ',
        'By agreeing to Vectra testing, I authorize Vectra Billing to obtain a consumer credit report on me from a consumer reporting agency selected by Vectra Billing. I understand and agree that Vectra Billing may use my consumer credit report to confirm whether my income qualifies me for financial assistance. I further understand that this will be a soft credit inquiry and will not impact my credit in any way. ',
        'If I qualify for financial assistance, I agree to provide Vectra Billing with any additional information or documentation that may be needed to confirm my qualification for the financial assistance program. ',
      ],
    },
    noSurprisesActUrl: BALANCE_BILLING_URL,
  },
});