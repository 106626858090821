import React from 'react';
import { NavLink } from "react-router-dom";
import MediaQuery from 'react-responsive';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import {
  Styles,
  Text,
  View,
  Navigation,
  NavigationTab,
  Button,
} from 'primase';

import MobileLink from './MobileLink';
import MobileMenu from './MobileMenu';
import { ExternalLink } from './Link';

import { useIsTheme } from '../utils/hooks';

import {
  HOME_URL,
  FINANCIAL_ASSISTANCE_URL,
  FAQ_URL,
  CONTACT_US_URL,
  MYRIAD_FINANCIAL_ASSISTANCE_URL,
} from '../constants';

const styles = {
  nav: {
    boxShadow: Styles.component.boxShadow.default,
  },
  navContainer: {
    overflow: 'hidden',
    backgroundColor: Styles.color.neutral.white,
  },
  mobileNav: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: Styles.spacing.sp2,
  },
  superscript: {
    marginTop: -4,
    fontSize: 8,
    '.ie &': {
      fontSize: 10,
    },
  },
  link: {
    textDecoration: 'none',
  },
  tab: {
    active: {
      color: Styles.color.neutral.black,
    },
  },
  button: {
    fontWeight:900
  },
  ctaButtonWrapper: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  backToFADesktopLinkWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  backToFADesktopLink: {
    fontWeight: 900,
    padding: 24,
  },
  backToFAMobileLink: {
    fontWeight: 900,
  },
};

const links = [
 {
   title: 'Home',
   href: HOME_URL,
 },
 {
   title: 'Financial assistance',
   href: FINANCIAL_ASSISTANCE_URL,
 },
 {
   title: 'FAQs',
   href: FAQ_URL,
 },
 {
   title: 'Contact us',
   href: CONTACT_US_URL
 }
];

const myriadFinancialAssistanceLink = {
  title: 'Back to financial assistance',
  href: MYRIAD_FINANCIAL_ASSISTANCE_URL
};

const NavTab = ({ active, title }) => (
  <NavigationTab
    active={active}
    key={`tab-${title}`}
    classes={styles.tab}
  >
    {
      typeof title === 'string' ? (
        <Text type="small">
          {title}
        </Text>
      ) : (title)
    }
  </NavigationTab>
);

NavTab.propTypes = {
  active: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

const DesktopLink = ({ link: { title, href }, active }) => (
  <View>
    <NavLink key={title} to={href} style={styles.link}>
      <NavTab active={active} title={title} />
    </NavLink>
  </View>
);

DesktopLink.propTypes = {
  link: PropTypes.object.isRequired,
  active: PropTypes.bool,
};

const isNavActive = (link, pathname = '') => (
  link.href === pathname
);


const Navbar = ({ classes, themeDetails }) => {
  const { pathname = '' } = useLocation();
  const { t } = useTranslation();
  const isGenesight = useIsTheme('neuro');

  const logo = (
    <NavLink to={isGenesight ? "/" : pathname}>
      <View classes={styles.logo}>
        <img alt="logo" src={themeDetails.logo} />
      </View>
    </NavLink>
  );
  
  return (
    <View classes={{...styles.nav, ...classes}}>
      <MediaQuery minWidth={Styles.layout.breakpoints.sm}>
        <Navigation
          prefix={logo}
          suffix={(
            <View style={styles.ctaButtonWrapper}>
              <ExternalLink
                  to={themeDetails.xifinPortalUrl}
                >
                <Button
                  css={styles.button}
                  secondary>{t('pages.home.ctaButton')}
                </Button>
              </ExternalLink>
            </View>
          )}
          classes={{ root: styles.navContainer }}
        >
        {
          isGenesight ? (
            links.map(link => (
              <DesktopLink key={link.href} active={isNavActive(link, pathname)} link={link} />
            ))
          ) : (
            <ExternalLink
              to={myriadFinancialAssistanceLink.href}
              styles={styles.backToFADesktopLinkWrapper}
            >
              <span style={styles.backToFADesktopLink} href={myriadFinancialAssistanceLink.href}>
                {t('pages.fa.backToFinancialAssistance')}
              </span>
            </ExternalLink>
          )
        }
        </Navigation>
      </MediaQuery>
      <MediaQuery maxWidth={Styles.layout.breakpoints.sm}>
        <View
          role="mobile-navbar"
          row
          inline
          classes={styles.mobileNav}
        >
          {logo}
          {isGenesight ? (
            <MobileMenu classes={{ root: styles.navContainer }}>
              {links
                .map(link => (
                  <MobileLink link={link} key={link.href} active={isNavActive(link, pathname)} />
                ))}
            </MobileMenu>
          ) : (
            <ExternalLink
                to={myriadFinancialAssistanceLink.href}
              >
              <span style={styles.backToFAMobileLink}>{t('pages.fa.backToFinancialAssistance')}</span>
            </ExternalLink>
          )}
        </View>
      </MediaQuery>
    </View>
  );
};

Navbar.propTypes = {
  classes: PropTypes.arrayOf('strings'),
  themeDetails: PropTypes.object,
}

export default Navbar;
