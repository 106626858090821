// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Redirect to given URL
 * @param {Object} props - Component props
 * @param {string} props.to - URL
 * @returns null
 */
export const RedirectExternal = ({ to }) => {
  if (to) {
    window.location.href = to;
  }

  return null;
}

export default RedirectExternal;
